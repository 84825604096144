import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Autocomplete,
  Popper,
  useTheme,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  InputAdornment,
  Stack,
} from "@mui/material";
import { Search, TuneOutlined, Clear } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Constants from "../../support/Constants";

const FiltrosColaboracionesMobile = ({
  filtros,
  setFiltros,
  getColaboraciones,
  categoriasColaboracion,
  targetPersonas,
  targetEmpresas,
  loading,
  showSourceAndTargetFilters,
}) => {

  const {
    emisor,
    receptor,
    titulo,
    categoria,
    tipo,
    estado,
    fechaDesde,
    fechaHasta,
  } = filtros;

  const [mergedTargets, setMergedTargets] = useState([]);
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const mergedArray = [...targetPersonas, ...targetEmpresas].map((item) => {
      return { name: item.name, memberAddress: item.memberAddress };
    });
    setMergedTargets(mergedArray);
  }, [targetEmpresas, targetPersonas]);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleAccordionChange("panel1")}
      sx={{ mb: 2, width: "100%" }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: 48,
          "&.Mui-expanded": {
            minHeight: 48,
            height: 48,
          },
        }}
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText }} />
        }
      >
        <Typography>Filtrar Colaboraciones</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: 1 }}>
        <Grid item lg={12}>
          <Grid item xs={12}>
            <TextField
              sx={{ marginBottom: 2 }}
              fullWidth
              id="outlined-basic"
              label="Título"
              variant="outlined"
              size="small"
              value={titulo}
              onChange={(event) =>
                setFiltros({ ...filtros, titulo: event.target.value })
              }
              InputProps={{
                endAdornment: titulo && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setFiltros({ ...filtros, titulo: "" })}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ marginBottom: 2 }}
              fullWidth
              id="outlined-basic"
              label="Tipo"
              variant="outlined"
              size="small"
              value={tipo}
              select={true}
              defaultValue={Constants.COLLABORATION_ANY}
              onChange={(event) =>
                setFiltros({ ...filtros, tipo: event.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key="_type_todos" value={Constants.COLLABORATION_ANY}>
                TODOS
              </MenuItem>

              <MenuItem
                key="_type_principle"
                value={Constants.COLLABORATION_PRINCIPLE}
              >
                Principio
              </MenuItem>
              <MenuItem
                key="_type_commitment"
                value={Constants.COLLABORATION_COMMITMENT}
              >
                Compromiso
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ marginBottom: 2 }}
              id="outlined-basic"
              label="Categoría"
              variant="outlined"
              size="small"
              fullWidth
              select={true}
              value={categoria}
              defaultValue={"TODAS"}
              onChange={(event) =>
                setFiltros({
                  ...filtros,
                  categoria: event.target.value,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key="_category_todas" value={"TODAS"}>
                TODAS
              </MenuItem>
              {categoriasColaboracion?.map((item) => (
                <MenuItem key={item.id} value={item.category}>
                  {item.category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ marginBottom: 1 }}
              fullWidth
              id="outlined-basic"
              label="Estado"
              variant="outlined"
              size="small"
              value={estado}
              select={true}
              defaultValue={Constants.COLLABORATION_STATUS_ANY}
              onChange={(event) =>
                setFiltros({ ...filtros, estado: event.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem
                key="_status_todos"
                value={Constants.COLLABORATION_STATUS_ANY}
              >
                TODOS
              </MenuItem>
              <MenuItem
                key="_status_pendiente"
                value={Constants.COLLABORATION_STATUS_PENDING}
              >
                Pendiente
              </MenuItem>
              <MenuItem
                key="_status_en_curso"
                value={Constants.COLLABORATION_STATUS_ACTIVE}
              >
                En curso
              </MenuItem>
              <MenuItem
                key="_status_finalizada"
                value={Constants.COLLABORATION_STATUS_FINALIZED}
              >
                Finalizada
              </MenuItem>
              <MenuItem
                key="_status_reportada"
                value={Constants.COLLABORATION_STATUS_REPORTED}
              >
                Reportada
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Desde"
                inputFormat="DD-MM-YYYY"
                value={fechaDesde}
                onChange={(newValue) =>
                  setFiltros({
                    ...filtros,
                    fechaDesde: newValue,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: 2 }}
                    size="small"
                    fullWidth
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Hasta"
                inputFormat="DD-MM-YYYY"
                value={fechaHasta}
                onChange={(newValue) =>
                  setFiltros({
                    ...filtros,
                    fechaHasta: newValue,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: 2 }}
                    size="small"
                    fullWidth
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Grid>

          {mergedTargets && showSourceAndTargetFilters && (
            <Grid item xs={12}>
              <Autocomplete
                name="emisor"
                options={mergedTargets}
                getOptionLabel={(option) =>
                  `${option?.name} (${option?.memberAddress?.substring(
                    0,
                    20
                  )}...)`
                }
                onChange={(event, value) => {
                  setFiltros({
                    ...filtros,
                    emisor: value ? value.memberAddress : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Socio emisor"
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: 2 }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          {mergedTargets && showSourceAndTargetFilters && (
            <Autocomplete
              name="receptor"
              options={mergedTargets}
              getOptionLabel={(option) =>
                `${option?.name} (${option?.memberAddress?.substring(
                  0,
                  20
                )}...)`
              }
              onChange={(event, value) => {
                setFiltros({
                  ...filtros,
                  receptor: value ? value.memberAddress : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Socio receptor"
                  variant="outlined"
                  size="small"
                  sx={{ marginTop: 2 }}
                  fullWidth
                />
              )}
            />
          )}

          <Stack mt={2} spacing={2} direction="column">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                setExpanded(!expanded);
                getColaboraciones(
                  emisor,
                  receptor,
                  titulo,
                  categoria,
                  tipo,
                  estado,
                  fechaDesde,
                  fechaHasta
                );
              }}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Stack>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FiltrosColaboracionesMobile;

import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { useNotification } from "../../hooks/useNotification";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import SociosContext from "../../context/socios/SociosContext";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { getCollaborations } from "../../services/CollaborationsServices";
import { getCollaborationCategories } from "../../services/ParametersServices";
import { getPrinciples } from "../../services/PrinciplesServices";
import { ColaboracionesTable } from "../../components/colaboraciones/ColaboracionesTable";
import { ColaboracionesCard } from "../../components/colaboraciones/ColaboracionesCard";

const ColaboracionesPage = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const pathname = window.location.pathname;

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [filtros, setFiltros] = useState({
    titulo: "",
    tipo: 0,
    categoria: "TODAS",
    fechaDesde: moment().startOf("day").subtract(1, "year"),
    fechaHasta: moment().endOf("day"),
    estado: 0,
    emisor: "",
    receptor: "",
  });

  const {
    titulo,
    tipo,
    categoria,
    fechaDesde,
    fechaHasta,
    estado,
    emisor,
    receptor,
  } = filtros;

  const [modeEdit, setModeEdit] = useState(false);
  const [targetPrincipios, setTargetPrincipios] = useState([]);

  const [categoriasColaboracion, setCategoriasColaboracion] = useState([]);
  const [colaboracionesData, setColaboracionesData] = useState([]);
  const [colaboracionSelected, setColaboracionSelected] = useState(null);
  const [comentariosColaboracion, setComentariosColaboracion] = useState([]);
  const [openModalColaboracion, setOpenModalColaboracion] = useState(false);
  const [openModalIniciar, setOpenModalIniciar] = useState(false);
  const [openModalTrabajar, setOpenModalTrabajar] = useState(false);
  const [openModalFinalizar, setOpenModalFinalizar] = useState(false);
  const [openModalReporte, setOpenModalReporte] = useState(false);
  const [loading, setLoading] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const { compromisos, getCompromisosEnabled } = useContext(CompromisosContext);

  const { socios, getSocios } = useContext(SociosContext);

  const handleAgregarColaboracion = () => {
    setOpenModalColaboracion(true);
    setModeEdit(false);
    setColaboracionSelected(null);
  };

  const handleEditarColaboracion = (colaboracionSelected) => {
    setOpenModalColaboracion(true);
    setModeEdit(true);
    setColaboracionSelected(colaboracionSelected);
    setComentariosColaboracion(colaboracionSelected.comments);
  };

  const handleIniciarColaboracion = (colaboracionSelected) => {
    setOpenModalIniciar(true);
    setModeEdit(false);
    const colaboracionConTituloTipo = {
      ...colaboracionSelected,
      tipoDeColaboracionTitulo:
        obtenerTituloDeTipoColaboracionSelected(colaboracionSelected),
    };
    setColaboracionSelected(colaboracionConTituloTipo);
  };

  const obtenerTituloDeTipoColaboracionSelected = (colaboracionSelected) => {
    if (colaboracionSelected) {
      const code = colaboracionSelected.collaborationTypeCode;
      if (code > 0) {
        if (
          colaboracionSelected.collaborationType ==
          Constants.COLLABORATION_PRINCIPLE
        ) {
          return targetPrincipios?.find(
            (p) => p.code == colaboracionSelected.collaborationTypeCode
          )?.title;
        } else {
          return compromisos?.find(
            (c) => c.code == colaboracionSelected.collaborationTypeCode
          )?.title;
        }
      }
    }
  };

  const handleTrabajarColaboracion = (colaboracionSelected) => {
    setOpenModalTrabajar(true);
    setModeEdit(false);
    const colaboracionConTituloTipo = {
      ...colaboracionSelected,
      tipoDeColaboracionTitulo:
        obtenerTituloDeTipoColaboracionSelected(colaboracionSelected),
    };
    setColaboracionSelected(colaboracionConTituloTipo);
    setComentariosColaboracion(colaboracionSelected.comments);
  };

  const handleFinalizarColaboracion = (colaboracionSelected) => {
    setOpenModalFinalizar(true);
    setModeEdit(false);
    const colaboracionConTituloTipo = {
      ...colaboracionSelected,
      tipoDeColaboracionTitulo:
        obtenerTituloDeTipoColaboracionSelected(colaboracionSelected),
    };
    setColaboracionSelected(colaboracionConTituloTipo);
    setComentariosColaboracion(colaboracionSelected.comments);
  };

  const handleVerReporte = (colaboracionSelected) => {
    setOpenModalReporte(true);
    setModeEdit(false);
    setColaboracionSelected(colaboracionSelected);
  };

  const handleEliminarColaboracion = (colaboracionSelected) => {
    setLoading(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_COLLABORATIONS,
      "deleteCollaboration",
      colaboracionSelected.code
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
            setLoading(false);
          })
        );
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        closeSpinner();
        setLoading(false);
      });
  };

  const handleCloseModalColaboracion = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenModalColaboracion(false);
    setColaboracionSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalIniciar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalIniciar(false);
    setColaboracionSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalTrabajar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalTrabajar(false);
    setColaboracionSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalFinalizar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalFinalizar(false);
    setColaboracionSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalReporte = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getColaboraciones(
              emisor,
              receptor,
              titulo,
              categoria,
              tipo,
              estado,
              fechaDesde,
              fechaHasta
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();

        return; // No se cierra el modal
      }
    }

    setOpenModalReporte(false);
    setColaboracionSelected(null);
    setModeEdit(false);
  };

  const getColaboraciones = () => {
    const categoriaParsed = categoria === "TODAS" ? "" : categoria;
    setLoading(true);

    getCollaborations(
      emisor,
      receptor,
      titulo,
      categoriaParsed,
      tipo,
      estado,
      moment(fechaDesde).format("YYYY-MM-DD HH:mm:ss"),
      moment(fechaHasta).format("YYYY-MM-DD HH:mm:ss")
    )
      .then((response) => {
        setColaboracionesData(response);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const getCategoriasColaboracion = () => {
    getCollaborationCategories()
      .then((response) => {
        setCategoriasColaboracion(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };
  const getPrincipios = () => {
    getPrinciples()
      .then((response) => {
        const updatedResponse = response.map((item) => ({
          ...item,
          code: item.id,
        }));

        setTargetPrincipios(updatedResponse);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getColaboraciones();
    getCategoriasColaboracion();
    getPrincipios();
    getCompromisosEnabled();

    getSocios(
      Constants.MEMBER_TYPE_SIN_REGISTRO,
      Constants.CATEGORY_ANY,
      "",
      Constants.MEMBER_STATUS_ANY,
      true
    );
  }, []);

  const filterColaborations = (colaboracionesData, pathname) => {
    return colaboracionesData
      .filter((colaboracion) => {
        if (pathname.includes("MisColaboraciones")) {
          return (
            (colaboracion.status !== Constants.COLLABORATION_STATUS_PENDING &&
              colaboracion.targetMemberAddress.toLowerCase() ===
                Web3Manager.MEMBER_DATA.memberAddress.toLowerCase()) ||
            colaboracion.sourceMemberAddress.toLowerCase() ===
              Web3Manager.MEMBER_DATA.memberAddress.toLowerCase()
          );
        } else {
          return (
            colaboracion.status !== Constants.COLLABORATION_STATUS_PENDING ||
            colaboracion.sourceMemberAddress.toLowerCase() ===
              Web3Manager.MEMBER_DATA.memberAddress.toLowerCase()
          );
        }
      })
      .sort((a, b) => b.id - a.id);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          {pathname.includes("MisColaboraciones")
            ? "Mis Colaboraciones"
            : "Colaboraciones"}
        </Typography>
      </Box>
      <>
        {matches ? (
          <ColaboracionesCard
            loading={loading}
            filtros={filtros}
            setFiltros={setFiltros}
            getColaboraciones={getColaboraciones}
            showSourceAndTargetFilters={!pathname.includes("MisColaboraciones")}
            handleEditarColaboracion={handleEditarColaboracion}
            handleTrabajarColaboracion={handleTrabajarColaboracion}
            handleIniciarColaboracion={handleIniciarColaboracion}
            handleFinalizarColaboracion={handleFinalizarColaboracion}
            handleEliminarColaboracion={handleEliminarColaboracion}
            handleVerReporte={handleVerReporte}
            handleAgregarColaboracion={handleAgregarColaboracion}
            showSpinner={showSpinner}
            filterColaborations={filterColaborations}
            colaboracionesData={colaboracionesData}
            pathname={pathname}
            openModalColaboracion={openModalColaboracion}
            colaboracionSelected={colaboracionSelected}
            handleCloseModalColaboracion={handleCloseModalColaboracion}
            modeEdit={modeEdit}
            socios={socios}
            targetPrincipios={targetPrincipios}
            categoriasColaboracion={categoriasColaboracion}
            compromisos={compromisos}
            setLoading={setLoading}
            openModalIniciar={openModalIniciar}
            openModalTrabajar={openModalTrabajar}
            openModalFinalizar={openModalFinalizar}
            openModalReporte={openModalReporte}
            handleCloseModalIniciar={handleCloseModalIniciar}
            handleCloseModalTrabajar={handleCloseModalTrabajar}
            handleCloseModalFinalizar={handleCloseModalFinalizar}
            handleCloseModalReporte={handleCloseModalReporte}
            comentariosColaboracion={comentariosColaboracion}
          />
        ) : (
          <ColaboracionesTable
            loading={loading}
            filtros={filtros}
            setFiltros={setFiltros}
            getColaboraciones={getColaboraciones}
            showSourceAndTargetFilters={!pathname.includes("MisColaboraciones")}
            handleEditarColaboracion={handleEditarColaboracion}
            handleTrabajarColaboracion={handleTrabajarColaboracion}
            handleIniciarColaboracion={handleIniciarColaboracion}
            handleFinalizarColaboracion={handleFinalizarColaboracion}
            handleEliminarColaboracion={handleEliminarColaboracion}
            handleVerReporte={handleVerReporte}
            handleAgregarColaboracion={handleAgregarColaboracion}
            showSpinner={showSpinner}
            filterColaborations={filterColaborations}
            colaboracionesData={colaboracionesData}
            pathname={pathname}
            openModalColaboracion={openModalColaboracion}
            colaboracionSelected={colaboracionSelected}
            handleCloseModalColaboracion={handleCloseModalColaboracion}
            modeEdit={modeEdit}
            socios={socios}
            targetPrincipios={targetPrincipios}
            categoriasColaboracion={categoriasColaboracion}
            compromisos={compromisos}
            setLoading={setLoading}
            openModalIniciar={openModalIniciar}
            openModalTrabajar={openModalTrabajar}
            openModalFinalizar={openModalFinalizar}
            openModalReporte={openModalReporte}
            handleCloseModalIniciar={handleCloseModalIniciar}
            handleCloseModalTrabajar={handleCloseModalTrabajar}
            handleCloseModalFinalizar={handleCloseModalFinalizar}
            handleCloseModalReporte={handleCloseModalReporte}
            comentariosColaboracion={comentariosColaboracion}
          />
        )}
      </>
    </Container>
  );
};

export default ColaboracionesPage;
